import React, { useEffect, useState } from "react";
import { getNumberSection } from "../../APIs/getNumberSection";

const PrideSection = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getNumberSection()
      .then((res) => setData(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  if (!data) {
    return <div>loading..</div>;
  }
  return (
    <section className="pride-section">
      <div className="container-fluid">
        <h2 className="f-45 dark-tx text-center pb-5">We Take Pride in Our Numbers</h2>
        <div className="pride-cols">
          <div className="pride-wrap">
            <h2 className="f-40 medium">{data.projected_yearly}</h2>
            <p className="f-22 mb-0">Projected Yearly Savings to Small Businesses</p>
          </div>
          <div className="pride-wrap white">
            <h2 className="f-40 medium">{data.interchange_fee}</h2>
            <p className="f-22 mb-0">
              Interchange <br></br> Fee
            </p>
          </div>
          <div className="pride-wrap">
            <h2 className="f-40 medium">{data.assessment_fee}</h2>
            <p className="f-22 mb-0">
              Assessment <br></br> Fee
            </p>
          </div>
          <div className="pride-wrap white">
            <h2 className="f-40 medium">{data.authorization_fee}</h2>
            <p className="f-22 mb-0">
              Authorization <br></br> Fee
            </p>
          </div>
          <div className="pride-wrap">
            <h2 className="f-40 medium">{data.platform_fee}</h2>
            <p className="f-22 mb-0">
              Platform <br></br> Fee
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrideSection;
