import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const HeaderNew = () => {
  return (
    <header className="newhomeheader">
      <div className="container-fluid">
        <Navbar expand="lg homen-nav" className="px-0">
          <Navbar.Brand href="/">
            <img src={require('../../images/logoWhite.png').default} alt="img" className="web_logo" />
            <img src={require('../../images/logoWhite.png').default} alt="img" className="mob_logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/solutions">Solutions</Nav.Link>
              {/* <Nav.Link href="/vision">Vision</Nav.Link> */}
            </Nav>
            <Form inline>
              <Button className="dark-btn" href="/login">
                Get Started
              </Button>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default HeaderNew;
