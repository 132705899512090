import React, { useEffect, useState } from "react";
import Header from "../Components/Common/Header";
import Footer2 from "./Common/Footer2";
import Footer from "./Common/Footer";
import Header2 from "./Common/Header2";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { IoIosArrowForward } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { getSolutionsfirstSec, getSolutionsForthSec, getSolutionsSecondSec, getSolutionsThirdSec } from "../APIs/Solutions";
import { getSiteSettings } from "../APIs/getSiteSettings";
import { LoadingSpinerComponent } from "./Common/loadingspinner";
import HeaderNew from "./Common/HeaderNew";
import FooterNew from "./Common/FooterNew";

const SolutionsNew = () => {
    const [wayWork, setWayWork] = useState(null);
    const [siteSettings, setSiteSettings] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [firstSec, setFirstSec] = useState(false);
    const [secondSec, setSecondSec] = useState(false);
    const [thirdSec, setThirdSec] = useState(false);
    const [forthSec, setForthSec] = useState([]);

    const fetchData = () => {
        setLoading(true);
        getSolutionsfirstSec()
            .then((res) => { setFirstSec(res.data.data); setLoading(false) })
            .catch(function (err) { toast.error(err.response.data.message); });
        getSolutionsSecondSec()
            .then((res) => { setSecondSec(res.data.data); })
            .catch(function (err) { toast.error(err.response.data.message); });
        getSolutionsThirdSec()
            .then((res) => {
                setThirdSec(res.data.data);
            }).catch(function (err) { toast.error(err.response.data.message); });
        getSiteSettings()
            .then((res) => setSiteSettings(res?.data?.data))
            .catch((err) => console.log(err));
        getSolutionsForthSec()
            .then((res) => setForthSec(res?.data?.data))
            .catch((err) => console.log(err));

        setTimeout(() => {
            Loading && setLoading(false);
        }, 2000);
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (Loading) {
        return <LoadingSpinerComponent promiseInProgress={Loading} />;
    }

    return (
        <>
            <HeaderNew />
            <section className="rethinking-payment solutions">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h1 className="rethink-title">
                                <span className="white-tx">{firstSec?.main_heading?.split(" ")[0]}</span> {firstSec?.main_heading?.split(" ")[1]}
                            </h1>

                            <p dangerouslySetInnerHTML={{ __html: firstSec.sub_heading }} className="f-24 banner-txt white-tx" />

                            <div className="rethink-btns">
                                <a className="black-btn" href="/login">
                                    Start Now{" "}
                                    <span>
                                        <IoIosArrowForward />
                                    </span>
                                </a>
                                <a className="border-btn" href="/contact">
                                    Contact Sales{" "}
                                    <span>
                                        <IoIosArrowForward />
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="rethink-img text-center">
                                <img
                                    src={require("../images/mobile.png").default}
                                    alt="img"
                                    className="rethink-mob"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="payment-innovation">
                <div className="container-fluid">
                    <h2 className="text-center f-45 pb-4">
                        <span className="dark-tx">Payment </span> Innovation{" "}
                        <span className="dark-tx">for Small Business </span>
                    </h2>

                    <p dangerouslySetInnerHTML={{ __html: firstSec.sub_heading_two_content }} className="f-24" />

                </div>
            </section>
            <section className="our-services">
                <div className="container-fluid">
                    <h2 className="text-center border-lr dark-tx f-45 mb-5">
                        Our Services
                    </h2>
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5">
                            <div className="services-img">
                                <img
                                    src={require("../images/services.png").default}
                                    alt="img"
                                    className="rethink-mob"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="payment-content">
                                <h3 className="f-32 bold pb-3">{secondSec[0]?.title}</h3>

                                <p dangerouslySetInnerHTML={{ __html: secondSec[0]?.content }} className="f-22"></p>
                                <h3 className="f-32 bold pb-3 pt-3">{secondSec[1]?.title}</h3>
                                <p className="f-22">
                                    {secondSec[1]?.content}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center pt-5">
                        <div className="col-12 col-md-7">
                            <div className="payment-content bottom">
                                <h3 className="f-32 bold pb-3">{secondSec[2]?.title}</h3>

                                <p dangerouslySetInnerHTML={{ __html: secondSec[2]?.content }} className="f-22" />
                                <h3 className="f-32 bold pb-3 pt-3">
                                    {secondSec[3]?.title}
                                </h3>

                                <p dangerouslySetInnerHTML={{ __html: secondSec[3]?.content }} className="f-22" />
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="services-img text-right">
                                <img
                                    src={require("../images/services2.png").default}
                                    alt="img"
                                    className="rethink-mob"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="complete-payment">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h2 className="text-white f-45 pb-4">
                                {thirdSec?.main_heading}
                            </h2>
                            <p className="f-22 text-white">
                                {thirdSec?.sub_heading}
                            </p>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="payment-content">
                                <div className="payment-btn">
                                    <a href="/contact" className="white-btn">
                                        Contact Sales{" "}
                                        <span>
                                            <IoIosArrowForward />
                                        </span>
                                    </a>
                                </div>
                                <p className="pay-cnt f-22 text-white">
                                    {thirdSec?.sale_email_heading} <br></br>
                                    <span> {thirdSec?.sale_email}</span>
                                </p>
                                <p className="pay-cnt f-22 text-white">
                                    {thirdSec?.support_email_heading}
                                    <br></br>
                                    <span>{thirdSec?.support_email}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="getstarted-section solutions">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="started-sec">
                                <h2 className="f-45 dark-tx">Get Started Now</h2>
                                <p className="f-22">Start Accepting PayRink at your retail store, in person, online, or on your app</p>
                            </div>
                            <p className="f-22">
                                {wayWork?.get_started_now}
                            </p>

                        </div>
                        <div className="col-12 col-md-6 text-right">
                            <div className="Started-solutions">
                                <Nav.Link href="/login"><button className="btn dark-btn">Get Started</button></Nav.Link>
                            </div>
                            <div className="started-btns d-flex justify-content-end pt-3">
                                <a
                                    href="https://apps.apple.com/us/app/payrink/id1644082696"
                                    target="_blank"
                                    className="btn appbtn p-0"
                                >
                                    <img
                                        src={require("../images/appstore1.png").default}
                                        alt="app store"
                                    />
                                </a>
                                <a
                                    className="btn appbtn p-0"
                                    href="https://play.google.com/store/apps/details?id=com.u.payrink"
                                    target="_blank"
                                >
                                    <img
                                        src={require("../images/googleplay1.png").default}
                                        alt="google play"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="commerce-payments">
                <div className="container-fluid">
                    <h2 className="text-center f-45 mb-4">
                        E-Commerce Payments
                    </h2>
                    <p className="f-24 text-center">
                        We integrate with your checkout process.
                    </p>
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-8">
                            <div className="payment-wrapper">
                                <h3 className="f-32" style={{ color: "#000" }}>
                                    PayRink Explained
                                </h3>
                                {forthSec?.map(({ title, content }, index) => (
                                    <div key={index}>
                                        <h4 className="f-22 pay-title" style={{ color: "#000" }}>{title}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: content }} className="f-22 dynamic-content-strong" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="solution-img">
                                <img
                                    src={require("../images/shopingimg.png").default}
                                    alt="img"
                                    className="sol-img"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterNew />

        </>
    );
};
export default SolutionsNew;
