import React, { useEffect, useState } from 'react'
import { getPayRinkUse } from '../../APIs/PayRinkUse';

const UseRinks = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        getPayRinkUse().then(res => setData(res.data.data)).catch(err => console.log(err));
    }, [])

    if (!data) {
        return (
            <div>loading..</div>
        )
    }
    const ulStyle = {
        listStyleType: "none",
        columns: 2,
        WebkitColumns: 2,
        MozColumns: 2,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: "0",
    }
    return (
        <section className="userinks-section">
            <div className="container-fluid">
                <h2 className="f-45 pb-4">Where can you use PayRink</h2>
                {/*<div className="row">*/}
                {/*<div className="col-12 col-md-6">
                        <div className="userinks-list">*/}
                <ul style={ulStyle}>
                    {data.map(({ id, value }, index) => (
                        <li className='f-22' style={{ width: "50%", padding: "5px 0" }} key={id}>{index + 1}. {value}</li>
                    ))}
                </ul>
                {/*</div>
                    </div>*/}
                {/*<div className="col-12 col-md-6">
                        <div className="userinks-list">
                            <p className="f-22">
                                7. Paying for a flight ticket or a sports game ticket.
                            </p>
                            <p className="f-22">
                                8. Paying for services at your hair salon.
                            </p>
                            <p className="f-22">3. Paying rent at your apartment.</p>
                            <p className="f-22">
                                9. Paying for Movie tickets at your local theater.
                            </p>
                            <p className="f-22">
                                10. Paying for services at your local DMV or any other agency.
                            </p>
                            <p className="f-22">
                                11. Paying for school lunches for your children.
                            </p>
                            <p className="f-22">12. And many more…</p>
                        </div>
                    </div>*/}
                {/*</div>*/}
            </div>
        </section>
    )
}

export default UseRinks