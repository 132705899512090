import React, { useEffect, useState } from "react";
import { getPaymentComparison } from "../../APIs/PaymentComparison";
import { config } from "../../config";

const SeeDifference = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        getPaymentComparison().then(res => setData(res.data.data)).catch(err => console.log(err));
    }, [])

    if (!data) {
        return (
            <div>loading..</div>
        )
    }
    const tableFields = [
        { title: "Per Transaction Fee", fieldname: "per_trans_fee" },
        { title: "Transaction Fee per $1000 paid", fieldname: "trans_fee_thsd_paid" },
        { title: "Amount Business receives per $1000 paid", fieldname: "amnt_buss_thsd_paid" },
        { title: "Transaction fee per $100,000 paid", fieldname: "trans_fee_lac_paid" },
        {
            title: "Amount Business receives per $100,000 paid.", fieldname: "amnt_buss_lac_paid"
        },
    ]
    return (
        <section className="see-difference">
            <div className="container-fluid">
                <h2 className="f-45 text-center pb-5">See the Difference Below</h2>
                <div className="differencde-table">
                    <div className="table_outer">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th></th>
                                    {tableFields?.map(({ title, fieldname }) => (
                                        <td style={{ textAlign: "center" }} key={fieldname}>{title}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((colData, index) => (
                                    <tr key={colData.company_name}>
                                        <th><img src={`${config.api_URL}${colData.company_image}`} alt="logo_payemt" /></th>
                                        {
                                            tableFields.map(({ fieldname }) => (
                                                <td dangerouslySetInnerHTML={{ __html: colData[fieldname] }} key={fieldname} />
                                            ))
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default SeeDifference