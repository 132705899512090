import React from 'react'

const Footer = () => {

    function handleClick() {
        window.location.href = '/login'
    }
    return (
        <>
            <footer className="pt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 mb-2">
                            <img src={require("../../images/footerlogo.png").default} alt="img" />
                            <div>
                                <ul className="p-0 mt-3">
                                    <li>
                                        <p className="footer_info">+1-800-587-7841</p>
                                    </li>
                                    <li>
                                        <p className="footer_info">sales@payrink.com</p>
                                    </li>
                                    <li>
                                        <p className="footer_info">210 Delburg Street, Davidson, </p>
                                    </li>
                                    <li>
                                        <p className="footer_info"> NC 28036</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3 mb-2">
                            <h5>Quick Links</h5>
                            <div className="row quick_row">
                                <div className="col-md-6 col-sm-6 mb-1">
                                    <ul className="p-0 mt-3">
                                        <li>
                                            <a className="" href="/#">Home</a>
                                        </li>
                                        <li>
                                            <a className="" href="/solutions">Solutions</a>
                                        </li>
                                        {/* <li>
                                        <a className="" href="/vision">Vision</a>
                                    </li> 
                                    <li>
                                        <a className="" href="/blog">Blog</a>
                                    </li>*/}
                                        <li>
                                            <button className="btn getstarted" onClick={handleClick}>Get Started</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-sm-6 mb-1">
                                    <ul className="p-0 mt-3">
                                        {/* <li>
                                            <a className="" href="/aboutus">About us</a>
                                        </li> */}
                                        <li>
                                            <a className="" href="/contact">Contact us</a>
                                        </li>
                                        {/* <li>
                                        <a className="" href="/faq" >Help & FAQ</a>
                                    </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mb-2">
                            <h5>Subscribe to Our Newsletter</h5>
                            <div className="mt-3">
                                <form>
                                    <label>Email*</label>
                                    <input className="input101" placeholder="" type="text" />
                                    <button className="btn subbtn" disabled>Submit</button>
                                </form>
                            </div>
                        </div>

                        <div className="col-md-3 mb-2">
                            <h5>Follow Us On:</h5>
                            <div>
                                <ul className="p-0 mt-3 social_row">
                                    <li>
                                        <a className="social_links" >
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="social_links" >
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="social_links" >
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="copyright_section py-2">
                    <div className="container-fluid mt-3">
                        <div className="row">
                            <div className="col-md-6">
                                <p className="pb-0 mb-0">© 2023 by PayRink</p>
                            </div>
                            <div className="col-md-6 text-right">
                                <ul>
                                    <li>
                                        <a href="/terms" className="pb-0 mb-0">Terms of Services</a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy" className="pb-0 mb-0">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>

        </>
    )
}
export default Footer;