import React, { useEffect, useState } from "react";
import { getLeadingCards } from "../../APIs/getLeadingCards";

const AverageTransc = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        getLeadingCards()
            .then((res) => setData(res.data.data))
            .catch((err) => console.log(err));
    }, []);

    if (!data) {
        return <div>loading..</div>;
    }
    return (
        <section className="average-transaction">
            <div className="container-fluid">
                <h3 className="text-center f-40 mb-0">Average Transaction</h3>
                <h3 className="text-center f-40 bottom-space">
                    Cost by <span className="dark-tx">Leading Card </span>Networks
                </h3>
                <div className="row">
                    <div className="col-12 col-md-3 text-center">
                        <div className="average-icons">
                            <img
                                src={require("../../images/american.png").default}
                                alt="img"
                                className="avg-trans"
                            />
                            <h4 className="avg-title">{data.american_express}</h4>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        <div className="average-icons">
                            <img
                                src={require("../../images/discover.png").default}
                                alt="img"
                                className="avg-trans"
                            />
                            <h4 className="avg-title">{data.discover}</h4>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        <div className="average-icons">
                            <img
                                src={require("../../images/Master.png").default}
                                alt="img"
                                className="avg-trans"
                            />
                            <h4 className="avg-title">{data.master_card}</h4>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        <div className="average-icons">
                            <img
                                src={require("../../images/visa.png").default}
                                alt="img"
                                className="avg-trans"
                            />
                            <h4 className="avg-title">{data.visa}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AverageTransc