import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Navbar, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FooterNew from "./Common/FooterNew";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { getSiteSettings } from "../APIs/getSiteSettings";

const Terms = () => {
  const [siteSettings, setSiteSettings] = useState(null);
  getSiteSettings().then((res) => {
    setSiteSettings(res?.data?.data);
  }).catch((err) => console.log(err));
  return (
    <>
      <header className="contactheader">
        <div className="container-fluid">
          <Navbar expand="lg contact-nav" className="px-0">
            <Navbar.Brand href="/">
              <img
                src={require("../images/logoWhite.png").default}
                alt="img"
                className="web_logo"
              />
              <img
                src={require("../images/blackLogo.png").default}
                alt="img"
                className="mob_logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/solutions">Solutions</Nav.Link>
              </Nav>
              <Form inline>
                <Button className="dark-btn" href="/login">
                  Get Started
                </Button>
              </Form>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
      <section
        className="team-cnt-header text-center text-white"
        style={{ background: "#f26369", padding: "90px 0" }}
      >
        <div className="container-fluid">
          <h2
            className="cnt-title"
            style={{ fontSize: "50px", fontWeight: "bold" }}
          >
            Terms
          </h2>
        </div>
      </section>
      <section className="privacy-policy" style={{ background: "#f2f3f4" }}>
        <Container className="privacy-content">
          <div>
            <p className="MsoNormal" align="center" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: 'normal', verticalAlign: 'baseline' }}><b><u><span style={{ fontSize: '13.5pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>PayRink
              Terms</span></u></b></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>In order to use the payment functionality of our
              application, you must open a "Dwolla Account" provided by Dwolla,
              Inc. and you must accept the</span><span style={{ color: 'black' }}><a href="https://www.dwolla.com/legal/tos" target="_blank"><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', letterSpacing: '.15pt' }}>&nbsp;Dwolla
                Terms of Service</span></a></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}> and</span><span style={{ color: 'black' }}><a href="https://www.dwolla.com/legal/privacy" target="_blank"><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', letterSpacing: '.15pt' }}> Privacy Policy</span></a></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>.
                  Any funds held in or transferred through your Dwolla Account are held or
                  transferred by Dwolla's&nbsp;</span><span style={{ color: 'black' }}><a href="https://www.dwolla.com/legal/about-our-financial-institution-partners/" target="_blank"><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', letterSpacing: '.15pt' }}>financial institution partners</span></a></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>. You must be at least 18 years old to create a Dwolla
                    Account. You authorize us to collect and share with Dwolla your personal
                    information including full name, date of birth, social security number,
                    physical address, email address and financial information, and you are
                    responsible for the accuracy and completeness of that data. You understand that
                    you will access and manage your Dwolla Account through our application, and
                    Dwolla account notifications will be sent by us, not Dwolla. We will provide
                    customer support for your Dwolla Account activity, and can be reached at <Link to="/">https://www.payrink.com</Link>,
                    <a href={`mailto:${siteSettings?.support_email}`}>&nbsp;{siteSettings?.support_email}</a></span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We may amend the Terms at any time with notice that we
              deem to be reasonable under the circumstances, by posting the revised version
              on our website or communicating it to you through the Services (each a “Revised
              Version”). Your continued use of the Services after the posting of a Revised
              Version constitutes your acceptance of such Revised Version. Any Dispute as
              defined in this document that arose before the changes will be governed by the PayRink
              Terms in place when the Dispute arose.<b> </b></span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We may provide disclosures and notices required by law
              and other information about your Account (defined below) to you electronically,
              by posting it on our website, pushing notifications through the Services, or by
              emailing it to the email address listed in your Account. Electronic disclosures
              and notices have the same meaning and effect as if we had provided you with
              paper copies. Such disclosures and notices are considered received by you
              within twenty-four (24) hours of the time posted to our website, or within
              twenty-four (24) hours of the time pushed or emailed to you. If you wish to
              withdraw your consent to receiving electronic communications, contact PayRink
              Support. If we are not able to support your request, you may need to terminate
              your Account.</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>III. PayRink Account<a name="cash-account" /></span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>1. Eligibility and Account Registration</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Using PayRink requires that you open an
              account. You need to be a U.S. resident and at least 18 years old. PayRink is a
              payments platform enabling customers to pay business directly. Payments are
              only to be made for goods and services rendered. If you want to use your account
              balance to pay another PayRink Business user, then you’ll need to give us some
              more information about you that we will use to verify your identity.<br />
              <br />
              This is also the part where you tell us you own the email or phone number you
              registered with and that the personal information you provide to us is correct.
              You agree to cooperate with us so that we can verify your identity. We might
              use third parties to help us do so.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You must be a resident of the United States, at least 18
              years and the age of majority in your State of residence, and you must register
              for an account (your “Account”) to use the Service. Certain features of the
              Service may only be available for use in the United States. Some features, such
              as the ability to send money to a PayRink Business user with the balance in
              your Account, may be available only if you provide us with certain information
              about you and we are able to verify your identity.</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>A.
              Registering, Opening, Using, or Upgrading an Account</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>To register, open, use, or upgrade an
              Account, PayRink may require that you submit certain information, including but
              not limited to your name, email address, text-enabled mobile phone number,
              street address, zip code, date of birth, social security number, and a
              government issued form of identification to PayRink. You represent and warrant
              that you own the email or mobile phone number you register with and all
              information entered or collected in the course of creating your Account and any
              information you subsequently add or update from your settings (“Account
              Information”) is true and accurate, and you agree to keep your Account
              Information current. All Account Information is subject to the PayRink
              Trademark and Copyright policy. </span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>C. Validating and Authenticating Your Identity</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;You hereby authorize PayRink,
              directly or through PayRink’s use of third parties, to make any inquiries we
              consider necessary to validate your identity or authenticate your identity and
              Account Information and, for Business Accounts, your company or employer. This
              may include asking you for further information or documentation about your
              Account usage or identity, requiring you to take steps to confirm ownership of
              your email address, mobile phone number or financial instruments, or verifying
              your information against third party databases or through other sources. After
              we verify your identity, your Account will convert from a Restricted Account to
              an Unrestricted Account.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>In connection with your use of the Service, you may not refuse
              to (a) cooperate in an investigation concerning activity that potentially
              violates the law or the Terms, (b) provide confirmation of your identity, or
              (c) provide confirmation of any information you provide us. If you decline to
              provide us with the information that we request or if we cannot verify your
              identity to our satisfaction (in our sole judgment and discretion) for any
              reason, then your Account will remain a Restricted Account and you will not be
              able to use your Balance to make payments to other PayRink Business users. In
              addition, we reserve the right to change the account type, suspend or terminate
              the Account of anyone who provides inaccurate, untrue, or incomplete
              information.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>The first time you access your Account using a new device
              we may require you to authenticate your Account with additional information. If
              your Account cannot be verified you may be required to set up a new Account.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>3.
              Usernames</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>Your phone number is your username.
              This will be a unique identifier for someone to verify before paying money. </span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You can change your phone number anytime in the app. To
              pay another PayRink business, all you need is their phone number or Barcode to
              scan. </span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>4.
              PayRink Fees</span></h5>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>The fees for using PayRink are found in the "Stored
              Balance Disclosures" Section of these PayRink Terms. We reserve the right
              to charge for, or change the fees associated with, the use of PayRink at any
              time subject to reasonable notice and these PayRink Terms. </span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>1.
              Text Messages and Other Communications</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>Here’s where we describe your
              consent to receive texts from us and your representations regarding texts we
              send to others relating to your payments. You also consent to us sending
              messages to your email. You can opt out of communications in ways that are
              specific to that communication method, such as by unsubscribing from emails, or
              replying to text messages with ‘STOP’.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>By providing us with a mobile number, either when you
              sign up for an Account or update the contact information associated with your
              Account, you consent to receiving text (SMS) messages and push notifications
              from us. Such communications may include, but are not limited to requests for
              secondary authentication, receipts, reminders, notifications regarding updates
              to your account or account support, and marketing or promotional
              communications. You acknowledge that you are not required to consent to receive
              promotional texts or calls as a condition of using the Services. Call and text
              message communications may be generated by automatic telephone dialing systems.
              Standard message and data rates applied by your cell phone carrier may apply to
              the text messages we send you.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>If you provide us with the mobile number of any business
              when initiating a payment through the Service, you represent and warrant to us
              that the business or entity has consented to receive text messages from us
              related to that payment. Standard text messaging rates may apply based on your
              plan with your mobile carrier.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You may opt-out of receiving promotional email
              communications we send to you by following the unsubscribe options on such
              emails. You may opt out of any promotional phone calls by informing the caller
              that you would not like to receive future promotional calls. You may opt-out of
              text messages from PayRink by replying STOP or by changing your notification
              preferences in the app. You acknowledge that opting out of receiving
              communications may impact your use of the Services.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>2.
              Compatible Mobile Devices and Third Party Carriers</span></h5>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>We do not warrant that the Services will be compatible with
              your mobile device or carrier. Your use of the Services may be subject to the
              terms of your agreements with your mobile device manufacturer or your carrier.
              You may not use a modified device to use the Services if the modification is
              contrary to the manufacturer’s software or hardware guidelines, including
              disabling hardware or software controls—sometimes referred to as “jail broken.”</span></p>
            <h4 style={{ margin: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{  letterSpacing: '.15pt', fontWeight: 'normal' }}>V. Data and
              Service Usage and Ownership<a name="data-ownership" /></span></h4>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>1.
              Your Content</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>You retain all rights to your
              content when you upload it into our services, but you do give us broad rights
              to use, modify, display your content in our services. You can see specifics on
              the rights you grant us below.<br />
              <br />
              Also, anything you provide us or make available to the public through our
              services must not contain anything that we think is objectionable (e.g.,
              illegal, obscene, hateful or harmful to you, our customers or us). We can
              remove any content at any time.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>The Services may include functionality for uploading or
              providing suggestions, recommendations, feedback, stories, photos, documents,
              logos, products, loyalty programs, promotions, advertisements and other
              materials or information (“Content”).</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You grant us and our subsidiaries, affiliates, and
              successors a worldwide, non-exclusive, royalty-free, fully-paid, transferable,
              irrevocable, perpetual, and sub-licensable right to use, reproduce, modify,
              adapt, publish, prepare derivative works of, distribute, publicly perform, and
              publicly display your Content throughout the world in any media for any reason,
              including to provide, promote, and/or incorporate into the Services. You retain
              all rights in your Content, subject to the rights you granted to us in these PayRink
              Terms. You may modify or remove your Content via your Account or by terminating
              your Account, but your Content may persist in historical, archived or cached
              copies and versions thereof available on or through the Services.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You will not upload or provide Content or otherwise post,
              transmit, distribute, or disseminate through the Services any material that:
              (a) is false, misleading, unlawful, obscene, indecent, lewd, pornographic,
              defamatory, libelous, threatening, harassing, hateful, abusive, or
              inflammatory; (b) encourages conduct that would be considered a criminal
              offense or gives rise to civil liability; (c) breaches or infringes any duty
              toward or rights of any person or entity, including rights of publicity,
              privacy or intellectual property; (d) contains corrupted data or any other
              harmful, disruptive, or destructive files; (e) advertises products or services
              competitive with PayRink’s or its partners’ products and services, as
              determined by us in our sole discretion; or (f) in our sole judgment, is
              objectionable, restricts or inhibits any person or entity from using or
              enjoying any portion of the Services, or which may expose PayRink, its
              affiliates or its customers to harm or liability of any nature.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>Although we have no obligation to monitor any Content, we
              have absolute discretion to remove Content at any time and for any reason
              without notice. PayRink may also monitor such Content to detect and prevent
              fraudulent activity or violations of the PayRink Terms. You understand that by
              using the Services, you may be exposed to Content that is offensive, indecent,
              or objectionable. We take no responsibility and assume no liability for any
              Content, including any loss or damage to any of your Content.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>2.
              Security</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>We take security incredibly
              seriously, but can’t guarantee that bad actors will not gain access to your
              personal information. You need to do your part by keeping your passwords safe,
              being smart about who has access to your account and which of your devices can
              access our services, and letting us know if you think an unauthorized person is
              using your account.<br />
              <br />
              If there is ever a dispute about who owns your account, we are the decider.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>We have implemented technical and organizational measures
              designed to secure your personal information from accidental loss and from
              unauthorized access, use, alteration, or disclosure. However, we cannot
              guarantee that unauthorized third parties will never be able to defeat those
              measures or use your personal information for improper purposes. You provide
              your personal information at your own risk.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You are solely responsible for safeguarding your password
              and for restricting access to the Services from your compatible mobile devices,
              computers, or through remote access. You will immediately notify us of any
              unauthorized use of your password or Account or any other breach of security.
              Notwithstanding Sections XI.18 and XI.19, in the event of any dispute between
              two or more parties as to account ownership, we will be the sole arbiter of
              such dispute in our sole discretion. Our decision (which may include
              termination or suspension of any Account subject to dispute) will be final and
              binding on all parties.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>3.
              Your License</span></h5>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>We grant you a limited, non-exclusive, revocable,
              non-transferable, non-sublicensable license to use the Services, as authorized
              in these PayRink Terms. We may make updates to the Services available to you,
              which you must accept to continue using the Services. Any such updates may be
              subject to additional terms made known to you at that time.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>4.
              Ownership</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>While we’re happy to let you use our
              services, you don’t have any ownership rights in them. We can act on any ideas
              you share with us about how we can improve our products and services free of
              charge.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>We reserve all rights not expressly granted to you in
              these PayRink Terms. The Services are protected by copyright, trademark,
              patent, and other laws of the United States and other countries. We own all
              rights, title, interest in and to the Services and all copies of the Services.
              These PayRink Terms do not grant you any rights to our trademarks or service
              marks.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>For the purposes of these PayRink Terms, “Intellectual
              Property Rights” means all patent rights, copyright rights, mask work rights,
              moral rights, rights of publicity, trademark, trade dress and service mark
              rights, goodwill, trade secret rights, and other intellectual property rights
              that may exist now or come into existence in the future, and all of their
              applications, registrations, renewals and extensions, under the laws of any
              state, country, territory or other jurisdiction.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You may submit feedback, comments, or ideas about the
              Services (“Ideas”). Submitting Ideas is entirely voluntary, and we will be free
              to use such Ideas as we see fit and without any obligation to you.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>5.
              Sharing of Your Information</span></h5>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>We will share Account Information
              and transaction information, including your name, the amount, and a
              description, with the other party to your transaction and in accordance with our
              Privacy Notice.&nbsp; </span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>6.
              Copyright and Trademark Infringement</span></h5>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>We respect the intellectual property
              rights of others and ask you to do the same. We have adopted a Copyright and
              Trademark Policy&nbsp;regarding any third-party claims that your material
              infringes the rights of others. We respond to all valid notices of such
              infringement, and our policy is to suspend or terminate the accounts of repeat
              infringers.</span></p>
            <h4 style={{ margin: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{  letterSpacing: '.15pt', fontWeight: 'normal' }}>VI. Balance<a name="balance" /></span></h4>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>1.
              General</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>You may store funds in your PayRink,
              these make up your balance. You may add funds to your balance from your linked
              bank account. When you do, you are authorizing us to debit those sources. If we
              incur costs because you have insufficient funds in your account, you will cover
              those costs.</span></i></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>a.&nbsp;<strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Functionality.</span></strong>&nbsp;Your
              balance consists of the funds you have in your Account that are available for
              new transactions and are not subject to pending transactions (your “Balance”).
              You are solely responsible for all funds necessary to complete any payments
              initiated through the Service. You agree to reimburse PayRink for any fees,
              costs, or expenses it incurs as a result of insufficient or unavailable funds
              in connection with any payment you initiate through the Service.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>b.&nbsp;<strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Authorization.</span></strong>&nbsp;Each
              time you instruct us through the PayRink app to add funds from your linked
              Eligible Bank Account (defined below) to your Balance or make any other
              transfer, you authorize us to immediately debit the Eligible Bank Account&nbsp;
              linked to your account at the time of the transfer for the amount instructed,
              either in a single or recurring transaction, as applicable. You are also
              authorizing us to move funds to your applicable Eligible Bank Account for
              instructions to add funds that are canceled or otherwise returned. You can
              cancel this authorization by unlinking your Eligible Bank Account from your PayRink
              Account.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>c.&nbsp;<strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Limitations.</span></strong>&nbsp;PayRink
              may impose limits on the amount you can keep in your Balance and reserves the
              right to change these limits at any time. Funds in your Balance might not be
              available to use for all types of transactions. </span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>2.
              Funding Your Balance</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>There are several ways you can add
              funds to your Balance (crediting), which are described here.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>We may offer you the ability to “Add Money” to your Balance
              in other ways, including, but not limited to, the following:</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>a.&nbsp;<strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>External
              Account.</span></strong></span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><strong><i><span style={{ fontSize: '12.0pt', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Eligible
              Bank Account.</span></i></strong><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>&nbsp;You may use
                funds from your Eligible Bank Account to fund your Balance.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>3.
              Transferring Funds to Your Bank Account</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>You can transfer funds from your Balance
              out to your bank account (“Withdraw Money”). These transfers can take up to
              three days, or for a fee, be made same day. We may limit your ability to
              transfer funds to your bank account, based on the circumstances. For example,
              we may delay transfers while we screen for fraud or risks. You are responsible
              for all amounts owed on your account even after it is closed.</span></i></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>a.&nbsp;<strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Transfers
              to Your Bank Account.</span></strong>&nbsp;You may electronically transfer
              funds from your Balance to an Eligible Bank Account (“Withdraw Money”). By
              selecting an Eligible Bank Account as your deposit source you are requesting
              that we settle an electronic transfer to your bank account. PayRink will settle
              these electronic transfers (via the Automated Clearing House (“ACH”) of NACHA -
              The Electronic Payment Association (“NACHA”)) to your Eligible Bank Account in
              the amount you instruct, and you will not be able to cancel the ACH transfer.
              Such transfer to your Eligible Bank Account via ACH will typically arrive
              within three (3) business days.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>b.&nbsp;<strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Same Day
              Credits.</span></strong>&nbsp;If this feature is available to you, you may Transfer
              money from your Balance to your Eligible Bank Account same day, which is
              subject to the fees detailed in the Same Day Credits experience.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>c.&nbsp;<strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Limitations.</span></strong>&nbsp;PayRink
              may limit how many money withdrawals &nbsp;you can initiate from your PayRink to
              your Eligible Bank Account and the amount of funds you can transfer in a single
              transaction. We reserve the right to delay or further limit such transfers at
              our discretion to protect PayRink or a third party against the risk of
              reversals, chargebacks, claims, fees, fines, penalties and other liability. In
              addition, you may not transfer funds from your Balance to your Eligible Bank
              Account to evade a payment investigation. If you attempt to transfer your Balance
              while we are conducting an investigation, we may hold your funds at our
              discretion to protect PayRink or a third party against the risk of reversals,
              chargebacks, claims, fees, fines, penalties and other liability. We also may
              defer or redirect payout or restrict access to your funds as necessary to
              comply with applicable law, subpoena or court order, or if requested by any
              governmental entity. You will remain liable for all obligations related to your
              Account even after the Account is closed.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>4.
              Treatment of Funds</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>PayRink will not mix your funds with
              PayRink’s corporate funds. Your funds will be held in pooled bank accounts with
              the funds of other PayRink customers.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>If you do hold funds in your Balance, PayRink will hold
              such funds separate from its corporate funds and will not use your funds for
              any corporate purposes, nor will PayRink voluntarily make your funds available
              to its creditors in the event of bankruptcy. PayRink will combine your funds
              with the funds of other customers and place those pooled accounts in one or
              more bank accounts in PayRink’s name.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>5.
              Interest</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>You will not receive interest on the
              funds that you have in your stored balance.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You agree that you will not receive interest on or other
              monies derived from the funds that PayRink handles and places in pooled
              accounts. It is likely that PayRink will receive interest on funds held for its
              users. In consideration for your use of the Service, and with full knowledge
              and understanding that PayRink will receive this interest, you irrevocably
              transfer and assign to PayRink all rights that you may have in any interest
              that may accrue on funds held in pooled accounts.</span></p>
            <h4 style={{ margin: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{  letterSpacing: '.15pt', fontWeight: 'normal' }}>VII. </span><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;PayRink for Business</span><a name="C4B" /></h4>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>1. Transactions for Sellers of Goods or Services</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you are a &nbsp;seller of goods or services,
              we will process your transactions as an agent on your behalf. We will charge
              you a fee (0.3%) per transaction, crediting the balance of payments from your
              customers into your Balance. You allow us to void transactions if we believe
              that a chargeback for the transactions will occur.</span></i></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you are a seller of goods or services,
              your profile will be marked with an official insignia or will otherwise be
              designated as having a “PayRink for Business Account.” You must have an
              Unrestricted Account to be a PayRink for Business Seller. If you have a PayRink
              for Business Account, you are also subject to the PayRink for Business payment
              terms. </span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>In connection with your PayRink for Business Account, you
              authorize us to act as your agent for the purposes of holding, receiving, and
              disbursing funds on your behalf. Once you have a PayRink for Business Account
              you will be able to accept payments from PayRink customers for the sale of
              goods and services, and we may charge you a fee for doing so. This fee will be
              disclosed to you when you sign up for your PayRink for Business Account. Payments
              you receive, less the applicable fee, will be&nbsp;credited&nbsp;to your
              Balance. To protect the integrity of the system and reduce risk that a
              transaction may be reversed on (or charged back to) your PayRink for Business
              Account, in some cases where we reasonably believe a transaction is likely to
              be reversed (or charged back), you also direct and authorize us, as your agent,
              to void the transaction.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>2. Purchases from Sellers of Goods and Services</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You may use your account to purchase goods
              and services. By initiating a payment to a PayRink seller of goods and services,
              you agree to let that seller debit your account to complete the transaction.
              You can use your Balance to purchase goods and services from PayRink sellers of
              goods and services only after we have verified your identity.</span></i></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>As a PayRink customer, you may purchase
              goods and services from merchants who use PayRink (Sellers of goods and
              services). Note that you can fund these purchases from your Balance only if we
              have verified your identity as described above. If we have not yet verified
              your identity, then you will not be able to use your Balance to purchase goods
              or services from Sellers of goods and services who use PayRink. You may only
              use other authorized linked accounts for such transactions.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Typically, PayRink Businesses users will be identified to
              you by a badge. By initiating a payment transaction to a seller of goods and
              services, you authorize the PayRink Seller to charge your applicable Eligible
              Transaction Account to complete the purchase or donation and you consent to
              sharing information from your Account with the PayRink for Business Seller. In
              the event you are entitled to a reversal, refund, chargeback, or other
              adjustment associated with any purchase you made through PayRink, you also
              authorize a credit to the applicable Eligible Transaction Account or Eligible
              Credit Account to accomplish that transaction. Purchases from PayRink sellers
              of goods and services using your Eligible Bank Account will be processed by PayRink
              as an agent for PayRink Sellers of goods and services in accordance with these PayRink
              Terms.</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>XI. Transaction Information and Disputes<a name="disputes" /></span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>1. Receipts and Account Statements</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You can find your transaction receipts and
              account statements in your PayRink account.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Transaction receipts can be found in the activity section
              of your PayRink and by logging into your Account at PayRink app.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You have the right to receive an Account statement. You
              may view your Account statement by logging into your Account at PayRink app.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You may obtain information about the amount of money you
              have remaining in your Account and can view your 12-month and 24-month history
              of Account transactions in the App or at (</span><Link to="/">https://www.payrink.com</Link><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>).</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>2. Error and Dispute Resolution</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Contact us if you see any errors or issues
              with your account. Our contact info is below, along with a description of the
              process and timeline for us to look into your issue. We’ll need some basic
              information from you to begin our investigation.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you believe there are errors on your Account, or you
              have questions about your Account, you can:</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>a. Contact us through your Account in the app:</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Open your profile and tap Support</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Tap Dispute a Purchase and follow the
              prompts to Contact Support. Once you’ve contacted support, we’ll send you a
              form to initiate an investigation.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}> Please note (1) this is the
              only phone number customers can call for PayRink support, and (2) a PayRink
              representative will never ask you for your password, PIN or &nbsp;social security
              number.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>c. Write us at PayRink, Inc., Attn: PayRink Disputes, 210
              Delburg Street, Davidson, NC 28036.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>d. You can also email us at </span><span style={{ color: 'black' }}><a href={`mailto:${siteSettings?.support_email}`}>&nbsp;{siteSettings?.support_email}</a></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}> </span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Please contact us as soon as you can if you think an
              error has occurred on your Account. You can report an error up to 60 days after
              the date we sent you the first statement on which you believe the error
              occurred.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>In order for us to investigate your claim, you will need
              to provide:</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Your name and Account information
              (including your PayRink Phone number and your PayRink email);</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Why you believe there is an error,</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>The dollar amount involved; and</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Approximately when the error took place.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you contact us by telephone, we may require that you
              send us your complaint or question in writing within 10 business days. We will
              determine whether an error occurred within 10 business days after we hear from
              you and will correct any error promptly. If we need more time however, we may
              take up to 45 days to investigate your complaint or question. If we decide such
              a step is necessary, we will credit your Account within 10 business days for
              the amount you think is in error, so that you will have the money during the
              time it takes us to complete our investigation. If we ask you to put your
              complaint or question in writing and do not receive it within 10 business days,
              we may, at our discretion, not credit your Account.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>For errors involving new Accounts, as well as
              point-of-sale transactions, we may take up to 90 days to investigate your
              complaint or question. For new Accounts, we may take up to 20 business days to
              credit your Account for the amount you think is an error. We will tell you the
              results within three business days after completing our investigation. If we
              decide that there was no error, we will send you a written explanation. You may
              ask for copies of the documents that we used in our investigation. If you need
              more information about our error resolution procedures, call us at 1-843-252-5674,
              please note (1) this is the only phone number customers can call for PayRink
              support, and (2) a PayRink representative will never ask you for your password,
              PIN, or social security number.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>For purposes of these disclosures, our business days are
              Monday through Friday. Holidays are not included.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>3. Your Liability for Unauthorized Transactions</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Contact us as soon as possible if you think
              there has been unauthorized activity on your account. You could lose all the
              money in your Account.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Tell us AT ONCE if you believe your PayRink Account has
              been compromised or if an electronic fund transfer has been made by logging in
              to your Account without your permission (an “Unauthorized Transaction”).</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You may contact us through the Support tab of PayRink as
              described in the Error and Dispute Resolution section above or by writing to us at PayRink, Inc., Attn: PayRink Disputes, 210 Delburg
              Street, Davidson, NC 28036</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you tell us within 2 business days (Monday through
              Friday, holidays not included) after you learn of an Unauthorized Transaction,
              you can lose no more than $50. If you do NOT tell us within 2 business days
              after you learn of an Unauthorized Transaction, and we can prove we could have
              stopped someone from making an Unauthorized Transaction if you had told us, you
              could lose as much as $500. If your statement shows transfers that you did not
              make, including those made by code or other means, you must also tell us at
              once. If you do not tell us within 60 days of the date we sent you the first
              statement on which the Unauthorized Transaction appeared, you may not get back
              any money you lost after the 60 days if we can prove that we could have stopped
              someone from taking the money if you had told us in time. If a good reason
              (such as a long trip or a hospital stay) kept you from telling us, we will
              extend the time periods. If you have a PayRink for Business Account, you may be
              liable for all Unauthorized Transactions, regardless of when you report the
              activity to us.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>The following are NOT considered Unauthorized
              Transactions:</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you give someone access to your Account
              (e.g. by giving them your login information) and they use your Account without
              your knowledge or permission, unless you have notified us that transfers by
              that person are no longer authorized;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you, or someone else with whom you are
              acting in concert, act with fraudulent intent; or</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You reverse engineer or chargeback a
              transaction made with your Account.</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in' }}>A
              misdirected payment, such as a payment that is sent to the wrong person, is an
              authorized payment, and will not be considered an Unauthorized Transaction.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>4. Our Liability</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>When we will cover your losses for certain
              incomplete or incorrect transactions, detailed here.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If we do not complete a transaction to or from your
              Account on time or in the correct amount in accordance with these PayRink
              Terms, we will be liable for your losses or damages. However, there are some
              exceptions. We will not be liable, for instance:</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If, through no fault of ours, you have
              insufficient funds in your Account, Eligible Transaction Account to complete
              the transaction;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If your funds are subject to legal process
              or other encumbrance that restricts transfers;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If the PayRink was not working properly and
              you knew about the breakdown when you started the transaction;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If circumstances beyond our control prevent
              the transaction, despite reasonable precautions that we have taken.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>5. Disputes with your Funding Source</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>When you contest a transaction with your
              external funding source, your rights when requesting reimbursement for a
              completed transaction are determined by the funding source used to fund the
              applicable transaction.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Each of your external funding sources may provide you
              with different rights and resolution procedures for resolving disputes. Please
              consult the terms and conditions of your funding source to better understand
              your rights and their resolution procedures. All inquiries about a third party
              dispute should be directed to the external funding source.</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>XII. OTHER LEGAL TERMS<a name="other" /></span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>1. Recovery of Funds</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You give us permission to recover amounts
              you owe by debiting funds available in your PayRink account or any other
              account you may have with PayRink. This may involve us making attempts to
              recover less than the full amount you owe to us. We might contact you or take
              other legal actions to collect amounts due.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You authorize PayRink to recover funds from you in
              accordance with these Service Terms and any other agreement you have with us,
              including if you owe amounts to us or for reason of fraud or illegal activity.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You authorize PayRink to debit, charge, setoff against
              and otherwise recover funds from your Account, your Balance, any Eligible Bank
              Account, any connected PayRink Account, any Balance (as defined in the Payment
              Terms) or any Reserve (as defined in the Payment Terms) if applicable, and any
              payment instrument, linked bank, depository and other account registered in
              your Account. Your authorization will remain in full force and effect until the
              later of closure or termination of your Account, or the disbursement of all
              funds held on your behalf.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Further, in the event that any attempt to recover funds
              from you should fail, your authorizations hereunder include your grant to PayRink
              of new, original authorizations to recover all or less than all of the amount
              you owe us or belong to us. You authorize PayRink to take the above steps
              without prior notice to you and irrespective of (i) whether we have made demand
              under these PayRink Terms, the Payment Terms, the General Terms or any other
              agreement you have with us; and (ii) whether the obligation is contingent,
              matured or unmatured. Your authorization hereunder includes all authorizations
              to take the above steps in complete compliance with the Network Rules and the
              NACHA Rules (as defined in the Payment Terms). You agree that your grant of the
              authorizations hereunder has the same legal effect as if you had signed a paper
              containing the same terms.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If PayRink is unable to recover the funds by these means,
              PayRink may attempt to contact you or may take other legal actions to collect
              the amounts due, to the extent allowed by applicable law.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We may also recover funds from your Account in connection
              with a disputed transaction. For example:</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If a PayRink customer that sent you money
              disputes the payment and requests reimbursement for a payment you previously
              received, PayRink may recover the amount disputed or owed from your Balance.</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you initiate a transaction dispute with
              an external funding source and win that dispute, we may recover the funds from
              your Account.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If there aren’t sufficient funds in your Account to cover
              the disputed amount, you understand that PayRink’s recovery of these funds may
              cause your account to become negative.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>PayRink is not liable for funds that we recover from your
              Balance to cover: a negative balance on your linked PayRink account(s); the
              amount owed to PayRink under its terms of service; or a pending dispute or
              chargeback; or to protect against financial risk as outlined in these terms.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>2. Compliance with Governmental Authorities</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>PayRink may freeze, withhold, or remit funds in your
              Account in response to a facially valid subpoena, court order, search warrant,
              notice, or other binding order from a governmental authority or third party,
              including but not limited to tax levies, garnishment orders, or lien notices.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>3. Limitations on Use</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We may limit your funding sources to reduce
              risk. You agree to not abuse the payment system or the service. We may block or
              reverse any transaction, including those that violate our terms or the law, at
              your expense.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>PayRink may limit the funding sources available for a
              specific transaction at any time in its sole discretion.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You agree that you are independently responsible for
              complying with all applicable laws in all of your activities related to your
              use of the Service and for all communications you send through the Service. You
              also agree to be bound by any applicable NACHA rules.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You further agree that in connection with your use of the
              Service, or in the course of your interactions with PayRink, a PayRink customer
              or a third party, you will not&nbsp; use your Account or the Service in a manner
              that PayRink, reasonably believe to be an abuse of the payment&nbsp; system or a
              violation of payment association rules.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We reserve the right to block, refuse, or reverse any
              transaction, in our sole discretion. We will notify the affected parties
              promptly if we decide to do so, but notification is not required if the
              transaction is prohibited by these terms or applicable law. Neither we nor
              third parties to whom we assign or delegate rights or responsibilities will be
              liable for any claims or damages resulting from prohibited transactions. All
              costs for research and resolution for any misapplied, misposted or misdirected
              prohibited transactions will be your sole responsibility and not ours.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>4. Other Restrictions</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If you want to use our services, you can’t
              do certain bad things. If we think you are doing these bad things, we have the
              right to share your information with law enforcement.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>You may not, nor may you permit any third party, directly
              or indirectly, to:</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>engage in any activity that may be in
              violation of regulations administered by the United States Department of the
              Treasury’s Office of Foreign Asset Control (31 C.F.R. Parts 500-599).
              Prohibited activity includes but is not limited to the provision of Services to
              or for the benefit of a jurisdiction, entity, or individual blocked or
              prohibited by relevant sanctions authorities, including but not limited to
              activities in Iran, Cuba, North Korea, Syria, or the Crimean Region of the
              Ukraine. If found to be in apparent violation of these restrictions, your
              account could be terminated and your funds could be held for an indefinite
              period of time;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>access or monitor any material or
              information on any PayRink system using any manual process or robot, spider,
              scraper, or other automated means;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>except to the extent that any restriction
              is expressly prohibited by law, violate the restrictions in any robot exclusion
              headers on any Service, work around, bypass, or circumvent any of the technical
              limitations of the Services, use any tool to enable features or functionalities
              that are otherwise disabled in the Services, or decompile, disassemble or
              otherwise reverse engineer the Services;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>perform or attempt to perform any actions
              that would interfere with the proper working of the Services, prevent access to
              or use of the Services by our other customers, or impose an unreasonable or
              disproportionately large load on our infrastructure;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>copy, reproduce, alter, modify, create
              derivative works, publicly display, republish, upload, post, transmit, resell
              or distribute in any way materials, information or Services from PayRink;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>transfer any rights granted to you under
              these PayRink Terms;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>use the Services for the sale of firearms,
              firearm parts, ammunition, weapons or other devices designed to cause physical
              harm;</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>use the Services for any illegal activity
              or goods or in any way that exposes you, other PayRink users, our partners, or PayRink
              to harm; or</span></p>
            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '52.5pt', textIndent: '-.25in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '10.0pt', fontFamily: '"Courier New"', color: 'white', letterSpacing: '.15pt' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
            </span></span><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>otherwise use the Services except as
              expressly allowed under these Terms.</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If we reasonably suspect that your Account has been used
              for an unauthorized, illegal, or criminal purpose, you give us express
              authorization to share information about you, your Account, and any of your
              transactions with law enforcement.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>5. Limitations on Our Liability for Failure to Complete
              Transactions</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>There are some circumstances where we will
              not be liable if transactions do not go through. Those circumstances are listed
              here.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We will not be liable, for failure to complete
              transactions in the following circumstances: (a) Through no fault of ours,
              there are insufficient funds in your PayRink Account to complete the
              transaction; (b) A merchant refuses to accept your PayRink Payment; (c) The
              information supplied by you is incorrect, incomplete, ambiguous or untimely (d)
              There is a hold or your funds are subject to legal or administrative process or
              other encumbrance restricting their use; (e) We have reason to believe the
              requested transaction is unauthorized; (f) Circumstances beyond our control
              (such as fire, flood, power failure, strike, labor dispute, critical service
              provider failure, computer breakdown, telephone line disruption, government or
              regulatory action, or a natural disaster) prevent or delay the completion of
              the transaction, despite reasonable precautions that we have taken; or (g) Any
              other exception stated in our Agreement with you.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>6. Changes to External Account Numbers</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If your Eligible Bank Account changes, you may acquire
              that information from our financial services partner and update your Account
              accordingly.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>7. Our Relationship With You</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We are an independent contractor for all purposes, except
              that we act as your limited agent with respect to the custody and transfer of
              your funds for the purposes set forth herein only.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>8. Termination of Account</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We can terminate or deny you access to our
              services at any time, for any reason. You can also choose to deactivate your
              account at any time.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>We may terminate these PayRink Terms or any Terms, or
              suspend or terminate your Account or your access to any Service, at any time
              for any reason, including a violation of the Terms. We may add or remove,
              suspend, stop, delete, discontinue or impose conditions on Services or any feature
              or aspect of a Service. We will take reasonable steps to notify you of
              termination or these other types of Service changes by email or at the next
              time you attempt to access your Account. You may also terminate the PayRink
              Terms applicable to your Account by deactivating your Account at any time.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>9. Effect of Termination</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Once our relationship ends, we are not
              responsible for any losses you experience because of the termination of our
              services or for removing your data from our servers. Some terms of our agreement
              will still apply even after our relationship ends.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If these Terms or your Account is terminated or suspended
              for any reason: (a) the license and any other rights granted under these Terms
              will end, (b) we may delete your information and account data stored on our
              servers, and (c) we will not be liable to you or any third party for
              compensation, reimbursement, or damages for any termination or suspension of
              the Services, or for deletion of your information or account data. The
              following Sections of these PayRink Terms survive and remain in effect in
              accordance with their terms upon termination: (Your Content), (Ownership),
              (Copyright and Trademark Infringement),&nbsp; (Effect of Termination),&nbsp;
              (Indemnity),&nbsp; (No Warranties),&nbsp; (Limitation of Liability and Damages),&nbsp; (Third
              Party Products), XI.18 (Disputes), (Binding Arbitration), (Governing Law),
              (Assignment), and (Other Provisions).</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>10. Services Upon Closure of Account</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: 'normal', verticalAlign: 'baseline' }}><i><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>When your PayRink account is closed, we’ll
              settle any pending transactions and return remaining funds in your account to
              you. We may need to hold your funds if there is an investigation at the time
              your Account is closed.</span></i></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>Any pending transactions at the time of termination,
              closure, or suspension of your Account will be settled. Any funds that we are
              holding in custody for you at the time of termination, suspension, or closure
              of your Account, less any applicable fees, (1) may be made available for you to
              PayRink out in PayRink’s discretion and subject to the other conditions in
              these PayRink Terms, and (2) we may require you to link a new bank account that
              has not been associated with your Account to PayRink out such funds. We reserve
              the right to hold your funds at our discretion to protect PayRink or a third
              party against the risk of reversals, chargebacks, claims, fees, fines,
              penalties and other liability. In addition, if an investigation is pending at
              the time of closure, we may hold your funds until resolution of the
              investigation.</span></p>
            <p className="MsoNormal" style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>11. Unclaimed Property</span></p>
            <p className="MsoNormal" style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif', color: 'white', letterSpacing: '.15pt' }}>If your Account is inactive for an extended period of
              time it may be deemed “unclaimed” or “abandoned” under your state’s law. If
              this occurs, PayRink will provide you with notice as required by applicable
              law. If funds still remain in your Account, PayRink will escheat such funds as
              required by applicable law.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>13.
              Taxes.</span></h5>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You are responsible for all applicable taxes that arise
              from or as a result of your use of the Service. Other than taxes that may be
              charged by PayRink to you and remitted to the appropriate tax authorities on
              your behalf, any deductions or withholdings that are required by law shall be
              borne by you and paid separately to the relevant taxation authority.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You may be asked to provide certain information used to
              assist PayRink in complying with its obligations under the United States
              Internal Revenue Code and the applicable Treasury Regulations, or state and
              local governments. By providing PayRink with this information you are
              certifying that the information provided is true and accurately reflective of
              your use of the Services.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>When indicating that you are a PayRink for Business
              Seller, you certify that your use of Payment Services through PayRink is to
              receive payment(s) that are “reportable payment transactions” as defined in
              Treas. Reg. § 1.6050W–1(a)(3) and the associated applicable state and local
              regulations, including, but not limited to, the payments for goods and
              services.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>14. Indemnity</span></p>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>If you do something using our
              services that gets us sued or fined, you agree to cover our losses as described
              below.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>You will indemnify, defend, and hold us and our
              processors (and our respective employees, directors, agents, affiliates and
              representatives) harmless from and against any and all claims, costs, losses,
              damages, judgments, tax assessments, penalties, interest, and expenses
              (including without limitation reasonable attorneys’ fees) arising out of any
              claim, action, audit, investigation, inquiry, or other proceeding instituted by
              a person or entity that arises out of or relates to: (a) any actual or alleged
              breach of your representations, warranties, or obligations set forth in these
              Terms; (b) your wrongful or improper use of the Services; (c) your violation of
              any third-party right, including without limitation any right of privacy,
              publicity rights or Intellectual Property Rights; (d) your violation of any
              law, rule or regulation of the United States or any other country; (e) any
              third-party claims made by your Buyer regarding PayRink’s processing of your
              customer/Buyer’s Personal Information in connection with providing you with the
              Services; and (f) any other party’s access and/or use of the Services with your
              unique name, password or other appropriate security code.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>15.
              No Warranties</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>While we do our best to bring great
              products and services to our customers, we provide them as-is, without
              warranties. We are not responsible for any goods and services that you might
              buy or sell using PayRink for Business.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>THE USE OF “PAYRINK” IN SECTIONS XI.15 AND XI.16 MEANS PAYRINK,
              ITS PROCESSORS, ITS SUPPLIERS, AND ITS LICENSORS (AND THEIR RESPECTIVE
              SUBSIDIARIES, AFFILIATES, AGENTS, DIRECTORS, AND EMPLOYEES).</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>THE SERVICES ARE PROVIDED “AS IS” WITHOUT REPRESENTATION
              OR WARRANTY, WHETHER IT IS EXPRESS, IMPLIED, OR STATUTORY. WITHOUT LIMITING THE
              FOREGOING, PAYRINK SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>PAYRINK DOES NOT WARRANT OR GUARANTEE THAT THE SERVICES
              ARE ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICES WILL MEET YOUR
              REQUIREMENTS; THAT THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR
              LOCATION, UNINTERRUPTED, ERROR-FREE, WITHOUT DEFECT OR SECURE; THAT ANY DEFECTS
              OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>PayRink does not warrant, endorse, guarantee, or assume
              responsibility for any product or service advertised or offered by a third
              party. PayRink does not have control of, or liability for, goods or services
              that are paid for using the Services.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>16.
              Limitations of Liability and Damages</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>As described below, if something bad
              happens because of your use of our services, our liability is capped.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
              EVENT WILL PAYRINK BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
              SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
              DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES,
              THAT RESULT FROM THE USE OF, INABILITY TO USE, OR UNAVAILABILITY OF THE
              SERVICE. IN ALL CASES, PAYRINK WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT
              IS NOT REASONABLY FORESEEABLE.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>UNDER NO CIRCUMSTANCES WILL PAYRINK BE RESPONSIBLE FOR
              ANY DAMAGE, LOSS, OR INJURY RESULTING FROM HACKING, TAMPERING, OR OTHER
              UNAUTHORIZED ACCESS OR USE OF THE SERVICES OR YOUR PAYRINK ACCOUNT, OR THE
              INFORMATION CONTAINED THEREIN.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
              TOTAL LIABILITY OF PAYRINK IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES
              EARNED BY US IN CONNECTION WITH YOUR USE OF THE SERVICES DURING THE THREE (3)
              MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR
              LIABILITY, OR (B) $500.</span></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE
              ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR
              ANY OTHER BASIS, EVEN IF PAYRINK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGE. THE FOREGOING LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST EXTENT
              PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>17.
              Third Party Products</span></h5>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>All third party products included or sold with the
              Services are provided solely according to the warranty and other terms
              specified by such third party, who is solely responsible for service and
              support for its product. For service, support, or warranty assistance, you
              should contact the manufacturer directly. PAYRINK MAKES NO REPRESENTATIONS OR
              WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO SUCH THIRD PARTY PRODUCTS, AND
              EXPRESSLY DISCLAIMS ANY WARRANTY OR CONDITION OF MERCHANTABILITY,
              NON-INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>18.
              Disputes</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>When you see the word “Dispute” in
              Section XI.19, here’s what it means.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>For purposes of Section XI.19, “Disputes” are defined as
              any claim, controversy, or dispute between you and PayRink, its processors,
              suppliers or licensors (or their respective affiliates, agents, directors or
              employees), whether arising before or during the effective period of these
              Terms, and including any claim, controversy, or dispute based on any conduct of
              you or PayRink that occurred before the effective date of these Terms,
              including any claims relating in any way to these Terms or the Services, or any
              other aspect of our relationship.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>19.
              Binding Arbitration</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>This section provides details about
              how we will resolve disputes through the arbitration process.</span></i></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><i><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>If you are an individual PayRink user, this
              arbitration provision applies to you:</span></i></strong></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>General.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;You and PayRink agree that any and all
              Disputes, except those that are resolved informally or brought in a small
              claims court, will be arbitrated by a neutral arbitrator who has the power to
              award the same individual damages and individual relief that a court can. ANY
              ARBITRATION UNDER THESE PAYRINK TERMS WILL ONLY BE ON AN INDIVIDUAL BASIS;
              CLASS ARBITRATIONS, CLASS ACTIONS, REPRESENTATIVE ACTIONS, AND CONSOLIDATION WITH
              OTHER ARBITRATIONS ARE NOT PERMITTED. YOU WAIVE ANY RIGHT TO HAVE YOUR CASE
              DECIDED BY A JURY AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS ACTION
              AGAINST PAYRINK. If any provision of this arbitration agreement is found
              unenforceable, the unenforceable provision will be severed, and the remaining
              arbitration terms will be enforced (but in no case will there be a class or
              representative arbitration).</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Pre-Filing Requirement to Attempt to Resolve
              Disputes.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;Before
                an arbitration is commenced, you or PayRink agree to attempt to avoid the costs
                of formal dispute resolution by giving each other a full and fair opportunity
                to address and resolve a Dispute informally. Both parties recognize that this
                is an important requirement, and that breach of this requirement would be a
                material breach of the Terms. To provide this opportunity, before commencing
                any arbitration or suit, each party agrees to send to the other party a written
                Notice (“Notice”). Any Notice to PayRink should be sent by mail to PayRink,
                Inc., Attn: Arbitration Provision, 210 Delburg Street, Davidson, NC 28036. Any
                Notice sent to you will be sent to the address on file for your account. The
                Notice must: (i) include your name and account number; (ii) provide detailed
                information sufficient to evaluate the merits of the claiming party’s
                individualized claim and for the other party to determine if an amicable
                resolution is possible; and (iii) set forth the specific relief sought,
                including whatever amount of money is demanded and the means by which the
                demanding party calculated the claimed damages. Both parties agree that they
                will attempt to resolve a dispute through an informal negotiation within sixty
                (60) days from the date the Notice is sent. After that sixty (60) day period
                and not before, either party may elect, in writing sent to the other party,
                that it will pursue the matter either through small claims court or
                arbitration. The party receiving the notice shall then have seven (7) days to
                respond, including to elect for the case to be heard by a small claims court
                with jurisdiction. If either party elects small claims court, the dispute will
                be resolved in that forum and not through arbitration. </span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Scope of Arbitration.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;If we are not able to resolve
              the Dispute by informal negotiation or, as provided below, in a small claims
              court, all Disputes will be resolved finally and exclusively by binding
              individual arbitration with a single arbitrator (the “Arbitrator”) administered
              by the American Arbitration Association (https://www.adr.org) according to this
              Section. Except as set forth above, and for disputes subject to jurisdiction in
              small claims court, the Arbitrator shall be responsible for determining all
              threshold arbitrability issues, including issues relating to whether the PayRink
              Terms (or any aspect thereof) are enforceable, unconscionable or illusory and
              any defense to arbitration, including waiver, delay, laches, or estoppel.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Small Claims Court.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;Subject to applicable
              jurisdictional requirements, either party may elect to pursue a Dispute in a
              local small-claims court rather than through arbitration so long as the matter
              remains in small claims court and proceeds only on an individual basis. If a
              party has already submitted an arbitration demand to the AAA but before an
              arbitrator has been selected, the other party can send a written notice to the
              opposing party that it wants the Dispute decided by a small claims court. After
              receiving this notice and consistent with Consumer Rule 9, the AAA will
              administratively close the case.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Arbitration Procedures.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;The Federal Arbitration Act, 9
              U.S.C. §§ 1-16, including its procedural provisions, fully applies. So long as
              it is consistent with the AAA Rules, including Rule D-3(b), the arbitration
              shall occur through the submission of documents to one arbitrator. To the
              extent any in-person arbitration hearing is required, the arbitration hearing
              will take place as close to your hometown as practicable. The Arbitrator’s
              award will be binding on the parties and may be entered as a judgment in any
              court of competent jurisdiction. PayRink values your privacy, particularly with
              respect to your financial transactions and data. Each of the parties shall
              maintain the confidential nature of the arbitration and shall not (without the
              prior written consent of the other party) disclose to any third party the fact,
              existence, content, award, or other result of the arbitration, except as may be
              necessary to enforce, enter, or challenge such award in a court of competent
              jurisdiction or as otherwise required by applicable law. While an arbitrator
              may award declaratory or injunctive relief, the Arbitrator may do so only with
              respect to the individual party seeking relief and only to the extent necessary
              to provide relief warranted by the individual party’s claim. The Arbitrator’s
              decision and judgment thereon will not have a precedential or collateral
              estoppel effect.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Arbitration Fees.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;In accordance with the AAA
              Rules, the party initiating the arbitration (either you or us) is responsible
              for paying the applicable filing fee. For purposes of this arbitration
              provision, references to you and PayRink also include respective subsidiaries,
              affiliates, agents, employees, predecessors, successors and assigns as well as
              authorized users or beneficiaries of the Services.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Opt Out.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;You may reject this provision, in which
              case only a court may be used to resolve any Dispute. To reject this provision,
              you must send us an opt-out notice (the “Opt Out”) within thirty (30) days
              after you create a PayRink Account, or for existing customers who agree to this
              as a terms update, within thirty (30) days of agreeing to such updated terms.
              The Opt Out must be mailed to PayRink, Inc., Attn: Arbitration Provision, 210
              Delburg Street, Davidson, NC 28036 (please note that any correspondence not
              related to opting out sent to this address will not receive a reply). </span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>PAYRINK TERMS WILL ONLY BE ON AN
              INDIVIDUAL BASIS; CLASS ARBITRATIONS, CLASS ACTIONS, REPRESENTATIVE ACTIONS,
              AND CONSOLIDATION WITH OTHER ARBITRATIONS ARE NOT PERMITTED. YOU WAIVE ANY
              RIGHT TO HAVE YOUR CASE DECIDED BY A JURY AND YOU WAIVE ANY RIGHT TO
              PARTICIPATE IN A CLASS ACTION AGAINST PAYRINK. If any provision of this
              arbitration agreement is found unenforceable, the unenforceable provision will
              be severed, and the remaining arbitration terms will be enforced (but in no
              case will there be a class or representative arbitration).</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Pre-Filing Requirement to Attempt to Resolve
              Disputes.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;Before
                an arbitration is commenced, you or PayRink agree to attempt to avoid the costs
                of formal dispute resolution by giving each other a full and fair opportunity
                to address and resolve a Dispute informally. Both parties recognize that this
                is an important requirement, and that breach of this requirement would be a
                material breach of the Terms. To provide this opportunity, before commencing
                any arbitration or suit, each party agrees to send to the other party a written
                Notice (“Notice”). Any Notice to PayRink should be sent by mail to PayRink,
                Inc., Attn: Arbitration Provision, 210 Delburg Street, Davidson, NC 28036. Any
                Notice sent to you will be sent to the address on file for your account. The
                Notice must: (i) include your name and account number; (ii) provide detailed
                information sufficient to evaluate the merits of the claiming party’s
                individualized claim and for the other party to determine if an amicable
                resolution is possible; and (iii) set forth the specific relief sought,
                including whatever amount of money is demanded and the means by which the
                demanding party calculated the claimed damages. Both parties agree that they
                will attempt to resolve a dispute through an informal negotiation within sixty
                (60) days from the date the Notice is sent. After that sixty (60) day period
                and not before, either party may commence arbitration. Each party agrees that
                state courts in the City of Davidson and County of Mecklenburg, North Carolina,
                or federal court for North Carolina , referenced below, may enter injunctive
                relief to enforce the pre-filing requirements of this paragraph, including an injunction
                to stay an arbitration that has been commenced in violation of this paragraph.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>Except as set forth above, the
              Arbitrator shall be responsible for determining all threshold arbitrability
              issues, including issues relating to whether the PayRink Terms (or any aspect
              thereof) are enforceable, unconscionable or illusory and any defense to
              arbitration, including waiver, delay, laches, or estoppel.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Small Claims Court.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;Subject to applicable
              jurisdictional requirements, either party may elect to pursue a Dispute in a
              local small-claims court rather than through arbitration so long as the matter
              remains in small claims court and proceeds only on an individual basis. If a
              party has already submitted an arbitration demand to the AAA, the other party
              may, in its sole discretion, inform the AAA party that it chooses to have the
              Dispute heard in small claims court. At that time, the AAA will close the
              arbitration and the Dispute will be heard in the appropriate small claims
              court, with no fees due from the arbitration respondent.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Arbitration Procedures.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;The Federal Arbitration Act, 9
              U.S.C. §§ 1-16, including its procedural provisions, fully applies. Any
              arbitration hearing will occur in Davidson, North Carolina, at another mutually
              agreeable location or, if both parties agree, by telephone or videoconference.
              The Arbitrator’s award will be binding on the parties and may be entered as a
              judgment in any court of competent jurisdiction. PayRink values your privacy,
              particularly with respect to your financial transactions and data. Each of the
              parties shall maintain the confidential nature of the arbitration and shall not
              (without the prior written consent of the other party) disclose to any third
              party the fact, existence, content, award, or other result of the arbitration,
              except as may be necessary to enforce, enter, or challenge such award in a
              court of competent jurisdiction or as otherwise required by applicable law.
              While an arbitrator may award declaratory or injunctive relief, the Arbitrator
              may do so only with respect to the individual party seeking relief and only to
              the extent necessary to provide relief warranted by the individual party’s
              claim. The Arbitrator’s decision and judgment thereon will not have a
              precedential or collateral estoppel effect.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Arbitration Fees.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;In accordance with the AAA
              Rules, the party initiating the arbitration (either you or us) is responsible
              for paying the applicable filing fee. For purposes of this arbitration
              provision, references to you and PayRink also include respective subsidiaries,
              affiliates, agents, employees, predecessors, successors and assigns as well as
              authorized users or beneficiaries of the Services.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><strong><span style={{ color: 'white', letterSpacing: '.15pt', border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Opt Out.</span></strong><span style={{ color: 'white', letterSpacing: '.15pt' }}>&nbsp;You may reject this provision, in which
              case only a court may be used to resolve any Dispute. To reject this provision,
              you must send us an opt-out notice (the “Opt Out”) within thirty (30) days
              after you create a PayRink account, or for existing customers who agree to this
              as a terms update, within thirty (30) days of agreeing to such updated terms.</span></p>
            <p style={{ margin: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>The Opt Out must be mailed to PayRink,
              Inc., Attn: Arbitration Provision, 210 Delburg Street, Davidson, NC 28036. <strong><span style={{ border: 'none windowtext 1.0pt', padding: '0in', fontWeight: 'normal' }}>Court
                Proceedings.</span></strong>&nbsp;Subject to and without waiver of the
              arbitration provisions above, you agree that any judicial proceedings (other
              than small claims actions as discussed above) will be brought in and you hereby
              consent to the exclusive jurisdiction and venue. </span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>20.
              Governing Law</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>Our relationship is governed by the
              laws of North Carolina, federal law, or both.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>These PayRink Terms and any Dispute will be governed by
              the Federal Arbitration Act, as set forth above, and by North Carolina law
              and/or applicable federal law, without regard to its choice of law or conflicts
              of law principles.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>21.
              Assignment</span></h5>
            <p className="comment" style={{ marginTop: '12.0pt', marginBottom: '12.0pt', verticalAlign: 'baseline' }}><i><span style={{ color: 'white', letterSpacing: '.15pt' }}>You may not transfer any rights you
              have under our terms of service to anyone else.</span></i></p>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>These PayRink Terms, and any rights and licenses granted
              hereunder, may not be transferred or assigned by you and any attempted transfer
              or assignment will be null and void.</span></p>
            <h5 style={{ marginTop: '.5in', marginRight: '0in', marginBottom: '9.0pt', marginLeft: '0in', lineHeight: '16.8pt', verticalAlign: 'baseline' }}><span style={{ fontSize: '12.0pt', letterSpacing: '.15pt', fontWeight: 'normal' }}>22.
              Other Provisions</span></h5>
            <p style={{ marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', verticalAlign: 'baseline' }}><span style={{ color: 'white', letterSpacing: '.15pt' }}>These PayRink Terms, and any applicable Policies, are a
              complete statement of the agreement between you and PayRink regarding the
              Services. In the event of a conflict between these PayRink Terms and any other PayRink
              agreement or Policy, these PayRink Terms will prevail and control the subject
              matter of such conflict. If any provision of these PayRink Terms or any
              Additional Term is invalid or unenforceable under applicable law, then it will
              be changed and interpreted to accomplish the objectives of such provision to
              the greatest extent possible under applicable law, and the remaining provisions
              will continue in full force and effect. These PayRink Terms do not limit any
              rights that we may have under trade secret, copyright, patent, or other laws.
              No waiver of any term of these PayRink Terms will be deemed a further or
              continuing waiver of such term or any other term.</span></p>
            <p className="MsoNormal"><span style={{ fontSize: '12.0pt', lineHeight: '107%', fontFamily: '"Times New Roman",serif' }}>&nbsp;</span></p>
          </div>

        </Container>
      </section>
      {localStorage.getItem("access_token") !== null ? <FooterNew /> : <FooterNew />}
    </>
  );
};

export default Terms;
