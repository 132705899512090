import axios from "axios";
import { config } from "../config";

export function getPayRinkUse() {
    return axios.get(`${config.api_URL}/accounts/get_payrink_use`,
        {
            headers: {
                'Authorization': localStorage.getItem('access_token')
            }
        })
}