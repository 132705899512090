import React, { useEffect, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { getHomeContent } from '../../APIs/getHomeContent';

const RethinkingPayments = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        getHomeContent()
            .then((res) => setData(res.data.data))
            .catch((err) => console.log(err));
    }, []);

    if (!data) {
        return null;
    }

    return (
        <>
            <section className="rethinking-payment">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-7">
                            <h1 className="rethink-title">
                                <span className="white-tx">{data.main_heading.split(" ")[0]}</span> {data.main_heading.split(" ")[1]}
                            </h1>
                            <p className="banner-txt white-tx">
                                {data.sub_heading}
                            </p>
                            <div className="row payrink-business">
                                <div className="col-12 col-md-12">
                                    <div className="business-left">
                                        <p className="f-24 text-pink mb-0">
                                            {data.other_trans_title}
                                            <span className="dark-txt large"> {data.other_trans_rate} </span> Per
                                            Transaction
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="business-right">
                                        <p className="f-24 text-pink mb-0">{data.payrink_trans_title}
                                            <span className="green-txt large"> {data.payrink_trans_rate}</span> Per
                                            Transaction
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="rethink-btns">
                                <a className="black-btn" href="/login">
                                    Start Now
                                    <span>
                                        <IoIosArrowForward />
                                    </span>
                                </a>
                                <a className="border-btn" href="/contact">
                                    Contact Sales
                                    <span>
                                        <IoIosArrowForward />
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5">
                            <div className="rethink-img text-center">
                                <img
                                    src={require("../../images/homeqrcode.png").default}
                                    alt="img"
                                    className="rethink-mob"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="payrink-business">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="business-left">
                                <p className="f-24">
                                    {data.other_trans_title}{" "}
                                </p>
                                <p className="f-24 mb-0">
                                    <span className="dark-txt large pt-2">{data.other_trans_rate}</span> Per
                                    Transaction
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="business-right">
                                <p className="f-24">{data.payrink_trans_title}</p>
                                <p className="f-24 mb-0">
                                    {" "}
                                    <span className="green-txt large pt-2">{data.payrink_trans_rate}</span> Per
                                    Transaction
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>

    )
}

export default RethinkingPayments