import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import validator from 'validator';
import { signupApi, generate_otp } from '../../APIs/Signupservice';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import OtpInput from 'react-otp-input';
import Header from './Header';
import Footer from './Footer';
import { useHistory } from 'react-router-dom';


const Signup = (props) => {
    useEffect(() => {
        localStorage.removeItem('access_token')
    }, [])

    const history = useHistory()
    const [checked, setChecked] = useState(false)
    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [successAfterOtp, setSuccessAfterOtp] = useState(false);

    let [otp, setOtp] = useState('');

    function handleClick(event) {
        setOtp(event)

    }

    const modalTrack = (event) => {
        // event.preventDefault(); 
        setSuccessAfterOtp(false)
        setShow(false)
        window.location.href = '/selectprofile'
        // props.history.push('/selectprofile')
    }


    const modalClose = () => {
        setShow(false)
    }

    const handleClose = (event) => {
        event.preventDefault(handleClose);

        const otpGet = otp;
        setOtp('')
        const data = {
            phone,
            email,
            otpGet,
            password,
            repassword,
            timezone,
            checked
        }
        toast.dismiss()
        if (otpGet !== '') {
            signupApi(data).then(Response => {
                if (Response.status === 200) {
                    localStorage.setItem('access_token', Response.data.token)
                    setShow(false);
                    setSuccessAfterOtp(true)
                    localStorage.setItem('email', email)
                    localStorage.setItem('phone', phone)
                    history.push('/selectprofile')
                }
                else {
                    toast.error('E-mail or phone already exists.')
                }
            }).catch(function (err) { toast.error(err.response.data.message) })
        }
        else {
            toast.error("Please enter valid OTP")
        }
    };
    const handleShow = (event) => {
        //   alert(email)
        event.preventDefault();
        toast.dismiss()
        if (phone.trim() === "") {
            toast.error("Phone Number field can't be empty")
            return false;
        }
        else if (phone.trim().length < 10) {
            toast.error('Phone number should be 10 digits.')
            return false;
        }
        else if (email.trim() === "") {
            toast.error("Email field can't be empty")
            return false;
        }
        else if (password === '') {
            toast.error("Password field can't be empty")
            return false;
        }
        else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}/.test(password)) {
            toast.error("Password must be eight characters long and contain at least one uppercase and lowercase alphabet,at least  one number and one special character.")
        }
        else if (repassword === '') {
            toast.error("Confirm Password field can't be empty")
            return false;
        }
        else if (password !== repassword) {
            toast.error("Password and confirm password doesn't match")
            return false;
        }
        else if (!/^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/.test(email)) {
            toast.error("Please enter valid email")
            return false;
        } else if (!checked) {
            toast.error("Please agree to our Terms of service and Privacy Policy.")
        }
        else {
            const data1 = {
                phone, email, password, repassword, checked
            }
            generate_otp(data1).then(Response => {
                toast.dismiss()
                if (Response.status === 200) {
                    toast.success(Response.data.message)
                    setShow(true)
                }
                else {
                    toast.error(Response.data.message)
                }
            }).catch(function (err) { toast.error(err.response.data.message) })

        }
    }

    function onlyNumber(e) {
        if (/^[0-9\b]+$/.test(e)) {
            setPhone(e);
            return true;
        } else if (e === '')
            setPhone('');

    }

    function HandleKey(e) {
        if (e.which == 32) {
            e.preventDefault()
        } else {
            setPassword(e.target.value)
        }
    }
    function HandleReKey(e) {
        if (e.which == 32) {
            e.preventDefault()
        } else {
            setRepassword(e.target.value)
        }
    }




    return (
        <>
            <Header />
            <section className="signup_section py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-7 m-auto">
                            <div className="text-center">
                                <h4 className="text-white mb-2">Let’s Get Started!</h4>
                                <p className="pt-2">Create an account to get all features</p>
                            </div>
                            <div className="signup_box my-4">
                                <Form>
                                    {/* <Form.Group className="mb-3" controlId="formBasicPhone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <span className="phonenumber_row">
                                        <span className="country_code">+1</span>
                                       
                                        <Form.Control as={InputMask}  inputmode="numeric" maxlength="10" placeholder="E.g. (987-654-3210)" value = {phone} onChange={(e) => onlyNumber(e)}/>
                                       
                                    </span>
                                </Form.Group> */}
                                    {/* <InputMask mask="999-999-9999" value = {phone} onChange={(e) => {
                                    console.log(e.target.value)
                                    setPhone(e.target.value)}} ></InputMask> */}
                                    <Form.Group className="mb-3 phone_input2" controlId="formBasicEmail">
                                        <Form.Label>Phone Number</Form.Label>
                                        <PhoneInput
                                            placeholder="E.g. (987-654-3210)"
                                            disableCountryCode={true}
                                            disableDropdown={true}
                                            onlyCountries={['us']}
                                            enableAreaCodes={true}
                                            country={'us'}
                                            value={phone}
                                            onChange={(e) => onlyNumber(e)} />

                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control type="email" placeholder="E.g. adam_smith007@gmail.com" onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter your password" onKeyDown={(e) => HandleKey(e)} onChange={(e) => setPassword(e.target.value.trim())} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Re-Enter your password" onKeyDown={(e) => HandleReKey(e)} onChange={(e) => setRepassword(e.target.value.trim())} />
                                    </Form.Group>

                                    <div className="text-center my-3 pt-2 terms d-flex align-items-center agreecheckox justify-content-center">
                                        <Form.Check checked={checked} onChange={(e) => setChecked(e.target.checked)} id='check' />
                                        <Form.Label htmlFor='check'>
                                            <p>By checking this box you agree to Our <a href="/terms" style={{ color: "#ed5557" }}>Terms of Service</a> and <a href='/privacy-policy' style={{ color: "#ed5557" }}>Privacy Policy</a>, as well as our partner Dwolla's <a style={{ color: "#ed5557" }} target="_blank" rel="noreferrer" href='https://www.dwolla.com/legal/tos/'>Terms of Service</a> and <a style={{ color: "#ed5557" }} target="_blank" rel="noreferrer" href='https://www.dwolla.com/legal/privacy/'>Privacy Policy</a></p>
                                        </Form.Label>
                                    </div>
                                    <Button className="btn signupbtn mt-4" onClick={handleShow}>
                                        Sign Up
                                    </Button>
                                    <div className="text-center mt-3 Existing">
                                        <p className="pb-0 mb-0 pt-2">Existing User? <a href="/login" className="text-white"><u>Login</u></a></p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Modal show={true} centered size='lg' backdrop={'static'} onHide={() => history.go("-1")}>
                <Modal.Header closeButton>
                    <Modal.Title><h4 className='text-white'>Information</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-5'>
                    <p className='text-center py-5 text-white'>We are not accepting new accounts at this time.</p>
                </Modal.Body>
            </Modal> */}

            <Modal show={show} onHide={modalClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="text-center w-100">
                        <Modal.Title className="text-white">Verification Code</Modal.Title>
                        <p className="pt-2 pb-0" onClick={handleClose}>Please type the verification code sent to</p>
                        <h6>+1{phone}</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="otp_row">
                                    <OtpInput
                                        inputMode="numeric"
                                        value={otp}
                                        inputStyle={"input101"}
                                        onChange={handleClick}
                                        numInputs={6}
                                        separator={<span></span>}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn signupbtn mt-3 mb-2" onClick={handleClose}>
                        Confirm
                    </button>
                </Modal.Footer>
                <div className="text-center mb-3 resend">
                    <p className="pb-0 mb-0 pt-2">I didn’t get the code  <a href="" onClick={(event) => { event.preventDefault(); handleShow(event); }} ><u>Resend</u></a></p>
                </div>
            </Modal>

            <Modal className="sucessmodal" show={successAfterOtp} onHide={modalTrack}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="text-right justify-content-end">
                    <i className="fa fa-close text-white" onClick={(event) => { modalTrack(event) }} style={{ cursor: "pointer" }}></i>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={require('../../images/check.png').default} alt="" />
                                <h5 className="text-white mt-3">Successfull!</h5>
                                <p>Your Mobile number has been successfully verified.</p>
                                {/* <p className="mt-4">Redirect to next screen within 5 seconds</p> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )
}
export default Signup;
