import { Nav } from "react-bootstrap";
import { LoadingSpinerComponent } from "./Common/loadingspinner";
import RethinkingPayments from "./HomeNew/RethinkingPayments";
import SeeDifference from "./HomeNew/SeeDifference";
import CreditCardProccessing from "./HomeNew/CreditCardProccessing";
import UseRinks from "./HomeNew/UseRinks";
import PrideSection from "./HomeNew/PrideSection";
import AverageTransc from "./HomeNew/AverageTransc";
import { useState, useEffect } from "react";
import { getWayWork } from "../APIs/getWayWork";
import FooterNew from "./Common/FooterNew";
import HeaderNew from "./Common/HeaderNew";

const HomeNew = () => {
  const [wayWork, setWayWork] = useState(null);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
    getWayWork()
      .then((res) => {
        setWayWork(res?.data?.data);
        // setLoading(false);
      })
      .catch((err) => console.log(err));
    setTimeout(() => {
      Loading && setLoading(false);
    }, 2000);
  }, []);

  if (Loading) {
    return <LoadingSpinerComponent promiseInProgress={Loading} />;
  }

  return (
    <>
      <HeaderNew />
      <RethinkingPayments />
      <section className="mission-statement">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="mission-img">
                <img
                  src={require("../images/misson_img.png").default}
                  alt="img"
                  className="rethink-mob"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mission-content">
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/D-3WyEIjgrU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              {/* <div className="mobile-misson-content">
                                <a href="https://www.youtube.com/embed/D-3WyEIjgrU" className="home-mission"> 
                                <div className="screenmobile-box">
                                <img
                                    src={require("../images/screenHome.png").default}
                                    alt="img"
                                    className="screen-mob"
                                />
                                <img
                                    src={require("../images/youtubeMob.png").default}
                                    alt="img"
                                    className="screen-icon"
                                />
                                </div>
                                </a>
                                
                            </div> */}
              <div className="mobile-misson-content">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/D-3WyEIjgrU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="problem-solution">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 text-center">
              <div className="solution-img-col">
                <img
                  src={require("../images/download.png").default}
                  alt="img"
                  className="rethink-soln"
                />
                <h3 className="f-40">Problem</h3>
                <p className="f-24">{wayWork?.problem_content}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="solution-img-col">
                <img
                  src={require("../images/solution.png").default}
                  alt="img"
                  className="rethink-soln"
                />
                <h3 className="f-40">Solution</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: wayWork?.solution_content,
                  }}
                  className="f-24"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SeeDifference />
      <section className="pricing-mobile">
        <div className="container-fluid">
          <div className="pricing-mobile-wrap">
            <img
              src={require("../images/priceMobile.png").default}
              alt="img"
              className="mobile-prc"
            />
          </div>
        </div>
      </section>
      <section className="average-difference">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="par-differtx">
                <h2 className="dark-title">PayRink Difference</h2>
                {/* <p className="f-24">
                                    {wayWork?.difference_content}
                                </p> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: wayWork?.difference_content,
                  }}
                  className="f-24"
                ></p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="diffrence-img text-right">
                <img
                  src={require("../images/difference.png").default}
                  alt="img"
                  className="rethink-dif"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <AverageTransc />
      {/* <CreditCardProccessing /> */}
      <section className="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="works-img">
                <img
                  src={require("../images/homeqrcode.png").default}
                  alt="img"
                  className="work-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="work-content">
                <h3 className="f-40 dark-tx">How it Works</h3>
                <p className="f-22">
                  <div
                    dangerouslySetInnerHTML={{ __html: wayWork?.how_it_work }}
                  />
                </p>
              </div>
              <div className="work-content">
                <h3 className="f-40 dark-tx">Ways To Pay</h3>
                <p className="f-22">
                  <div
                    dangerouslySetInnerHTML={{ __html: wayWork?.ways_to_pay }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="shopping-online">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="shopping-content">
                <h2 className="f-40 text-white">Shopping Online</h2>
                <p className="f-22 text-white">{wayWork?.shoping_content}</p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="shopping-img">
                <img
                  src={require("../images/shopingimg.png").default}
                  alt="img"
                  className="shop-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <UseRinks />
      <section className="managemoney-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="manage-img">
                <img
                  src={require("../images/withdrawmoney.png").default}
                  alt="img"
                  className="withdraw-img"
                />
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="withdraw-col">
                <h2 className="text-white f-45 pb-4">Managing your Money</h2>
                <p
                  className="f-22 text-white"
                  dangerouslySetInnerHTML={{
                    __html: wayWork?.managing_content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="getstarted-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-7 ml-auto">
              <div className="started-sec d-flex">
                <h2 className="f-45 dark-tx pb-4">Get Started Now</h2>
                <Nav.Link href="/login">
                  <button className="btn dark-btn">Get Started</button>
                </Nav.Link>
              </div>
              <p className="f-22">{wayWork?.get_started_now}</p>
              <div className="started-btns d-flex justify-content-end">
                <a
                  href="https://apps.apple.com/us/app/payrink/id1644082696"
                  target="_blank"
                  className="btn appbtn"
                >
                  <img
                    src={require("../images/appstore1.png").default}
                    alt="app store"
                  />
                </a>
                <a
                  className="btn appbtn"
                  href="https://play.google.com/store/apps/details?id=com.u.payrink"
                  target="_blank"
                >
                  <img
                    src={require("../images/googleplay1.png").default}
                    alt="google play"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PrideSection />
      <FooterNew />
    </>
  );
};
export default HomeNew;
